import React from 'react';
import { Modal, Button, Alert } from 'react-bootstrap';
import ChangePasswordForm from '../ChangePasswordForm';
import { useAuth } from '../AuthContext';

const Modals = ({
  showWarningModal,
  setShowWarningModal,
  warningContent,
  showProfileModal,
  setShowProfileModal,
  showChangePassword,
  setShowChangePassword,
}) => {
  const { user, logout } = useAuth();

  return (
    <>
      {/* Warning Modal */}
      <Modal show={showWarningModal} onHide={() => setShowWarningModal(false)} centered>
        <Modal.Header className="profile-modal-header" closeButton>
          <Modal.Title>{warningContent?.title || 'Subscription Expiration Warning'}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="profile-modal-body">
          {warningContent ? (
            <>
              <p><strong>User Name:</strong> {warningContent.userName}</p>
              <p><strong>Start Date:</strong> {warningContent.startDate}</p>
              <p><strong>End Date:</strong> {warningContent.endDate}</p>
              <p><strong>Days Remaining:</strong> {warningContent.daysRemaining}</p>
              <Alert variant="warning">{warningContent.message}</Alert>
            </>
          ) : (
            <Alert variant="warning">Subscription details are unavailable.</Alert>
          )}
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <Button className="btn" onClick={() => setShowWarningModal(false)}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Profile Modal */}
      {user && showProfileModal && ( // Only show modal if user exists and profile is explicitly triggered
        <Modal show={showProfileModal} onHide={() => setShowProfileModal(false)} centered>
          <Modal.Header className="profile-modal-header" closeButton>
            <Modal.Title>User Profile</Modal.Title>
          </Modal.Header>
          <Modal.Body className="profile-modal-body">
            <p><strong>User Name:</strong> {user.userName}</p>
            <p><strong>User ID:</strong> {user.userId}</p>
            <p><strong>Tehsil:</strong> {user.tehsil}</p>
            <p><strong>Mobile Number:</strong> {user.mobileNumber}</p>
            <p><strong>Mauza List:</strong> {user.mauzaList ? user.mauzaList.join(', ') : 'No Mauza available'}</p>
            <p><strong>Subscription Type:</strong> {user.subscriptionType}</p>
            <div className="modal-footer">
              <Button className="btn" onClick={() => setShowProfileModal(false)}>
                Close
              </Button>
              <Button className="btn btn-danger" onClick={() => logout()}>
                Logout
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {/* Change Password Modal */}
      <ChangePasswordForm show={showChangePassword} onHide={() => setShowChangePassword(false)} userId={user?.userId} />
    </>
  );
};

export default Modals;
